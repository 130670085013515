(function() {
  'use strict';

  var $ = jQuery.noConflict();

  var self, s;
  app.SliderWidget = {

    settings: {
      elBody: $('body'),
      elPrev: $('.slider-controllers__item--prev'),
      elNext: $('.slider-controllers__item--next')
    },

    init: function() {
      self = this;
      s = self.settings;

      s.carousel = $('.owl-carousel').owlCarousel({
                      items: 1,
                      loop: true,
                      autoplay: false,
                      autoplayTimeout: 100000000,
                      autoplayHoverPause: true,
                      smartSpeed: 1500
                    });

      self.bindUIActions();
    },

    bindUIActions: function() {
      s.elPrev.on('click', function() {
        s.carousel.trigger('prev.owl.carousel');
      });

      s.elNext.on('click', function() {
        s.carousel.trigger('next.owl.carousel');
      });
    }
  };

  app.SliderWidget.init();
})();
