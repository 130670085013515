(function() {
  'use strict';

  var $ = jQuery.noConflict();

  var self, s;
  app.GalleryWidget = {

    settings: {
      elProject: $('.project'),
      elPswpElement: document.querySelectorAll('.pswp')[0],
      galleries: {}
    },

    init: function() {
      self = this;
      s = self.settings;

      s.elProject.each(function() {
        self.createProjectItems($(this));
      });

      self.bindUIActions();
    },

    createProjectItems: function(el) {
      var name = el.data('name'),
          images = el.data('images').split(','),
          items = [];

      for(var index = 0; index < images.length; ++index) {
        var item = {},
            infos = images[index].split('|');

        item.src = infos[0];
        item.w = infos[1];
        item.h = infos[2];

        items.push(item);
      }

      s.galleries[name] = items;
      app.console(s.galleries[name]);
    },

    bindUIActions: function() {

      s.elProject.on('click', function() {
        self.createGallery($(this));
      });
    },

    createGallery: function(el) {

      var name = el.data('name'),
          items = s.galleries[name];

      var options = {
        index: 0,
        closeOnScroll: false,
        loop: false
      };

      // Initializes and opens PhotoSwipe
      var gallery = new PhotoSwipe( s.elPswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();
    }
  };

  app.GalleryWidget.init();
})();
