(function() {
  'use strict';

  var $ = jQuery.noConflict();

  var self, s;
  app.AboutWidget = {

    settings: {
      elToggle: $('.tabs__toggle'),
      elContent: $('.tabs__content'),
    },

    init: function() {
      self = this;
      s = self.settings;

      self.bindUIActions();
    },

    bindUIActions: function() {
      s.elToggle.on('click', function() {
        self.switchTab();
      });
    },

    switchTab: function(el) {
      s.elToggle.toggleClass('tabs__toggle--active');
      s.elContent.toggleClass('tabs__content--active');
    }
  };

  app.AboutWidget.init();
})();
