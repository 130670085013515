(function() {
  'use strict';

  var $ = jQuery.noConflict();

  var s;
  app.MenuWidget = {

    settings: {
      elHeader: $('.header'),
      elMenu: $('.navigation'),
      elMenuToggle: $('.navigation-toggle'),
      isMobile : $('html').hasClass('is-mobile'),
      orientation: window.orientation,
      didScroll: false,
      lastScrollTop: 0,
      delta: 5
    },

    init: function() {
      s = this.settings;

      this.bindUIActions();
      this.headerDisplay();
    },

    bindUIActions: function() {
      var self = this;

      s.elMenuToggle.bind('click', function() {
        self.toggleMenu();
      });

      s.elMenu.find('a').bind('click', function() {
        self.toggleMenu();
      });

      $(window).scroll(function(event) {
        s.didScroll = true;
      });

      $(window).bind('orientationchange', function(event){
        s.orientation = window.orientation;
      });
    },

    toggleMenu: function() {
      s.elMenuToggle.toggleClass('navigation-toggle--opened');
      s.elMenu.toggleClass('navigation--active');
    },

    headerDisplay: function() {
      var self = this;

      setInterval(function() {
        if (s.didScroll && (s.orientation === 0 || !s.isMobile)) {
          hasScrolled();
          s.didScroll = false;
        }
      }, 250);

      function hasScrolled() {
        var headerHeight = s.elMenu.parent().outerHeight(),
            scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

        if(Math.abs(s.lastScrollTop - scrollPos) <= s.delta) {
          return;
        }
        if (scrollPos > s.lastScrollTop && scrollPos > headerHeight){
          if(s.elMenu.hasClass('navigation--active')) {
            self.toggleMenu();
          }
          s.elHeader.addClass('header--up');
        }
        else {
          if(scrollPos + $(window).height() < $(document).height()) {
            s.elHeader.removeClass('header--up');
          }
        }

        s.lastScrollTop = scrollPos;
      }

    }
  };

  app.MenuWidget.init();
})();
