(function() {
  'use strict';

  if(document.querySelector('#contact-form') === null) return;

  document.addEventListener('DOMContentLoaded', function() {
      var form = document.querySelector('#contact-form');
      form.addEventListener('submit', function(event) {
          event.preventDefault();
  
          var result = document.querySelector('#form-result');
          var action = form.getAttribute('action');
          var method = form.getAttribute('method');
  
          fetch(action, {
              method: method,
              body: new FormData(form)
          })
          .then(function(response) {
              if (response.ok) {
                  return response.text();
              } else {
                  return response.json();
              }
          })
          .then(function(output) {
              if (result) {
                  result.innerHTML = output;
              }
          })
          .catch(function(error) {
              if (result) {
                  result.innerHTML = 'Error: ' + error;
              }
  
              throw new Error(error);
          });
      });
  });
})();